@import "../../../assets/scss/custom";

.Container {
  font-size: 2rem;
  color: $secondary;
  text-align: center;
}

.SizeLarge {
  font-size: 3rem;
}

.SizeMedium {
  font-size: 2rem;
}

.SizeSmall {
  font-size: 1rem;
}

.ColorContent {
  color: #FFF;
}

.ColorPrimary {
  color: $primary;
}

.ColorSecondary {
  color: $secondary;
}

.Center {
  text-align: center;
}
