@import '../../../assets/scss/common';

.Container {
  display: flex;
  margin: 0 -.5rem;

  @include for-phone-only {
    flex-direction: column;
  }

  & > div {
    margin-left: .5rem;
    margin-right: .5rem;
    flex-grow: 1;
    flex-basis: 0;
  }

}
