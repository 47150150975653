

.Title {
  margin: 0;
}

.Container {
  margin: 2rem;
  transition: transform 0.3s;
  transform: translateY(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  height: calc(100% - 4rem);
}

@media print {
  .Container {
    display: none;
  }
}

.CloseModal {
  cursor: pointer;
}

.ContainerSmall {
  width: 50%;
  left: 25%;
}

.ContainerMedium {
  width: 60%;
  left: 20%;
}

.ContainerFull {
  width: calc(100vw - 4rem);
  height: calc(100vh - 4rem);
  left: 0;
  top: 0;

  .Content {
    display: flex;
    height: 100%;
    width: 100%;
  }
}

.ContainerOpen {
  transition-delay: 0.3s;
  transform: translateY(0);
}

.Content {
  background: #fff;
  border-radius: 4px;
  z-index: 101;
  padding: 1rem;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  max-height: 100%;
  width: 100%;

  &.NoScroll {
    overflow-y: inherit;
  }
}

.Header {
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 1rem 0;
}
