@import "../../assets/scss/custom";

.Container {
  background: $white;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 1rem;
}

.ReservationInfo p {
  margin: 0;
}
