@import '../../../assets/scss/common';

.Container {
  margin-bottom: 0.75rem;
}

.Label {
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}


.Invalid {
  .Label {
    color: $danger;
  }

  .Input {
    border-color: $danger;
  }
}

.Input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid $dark-gray;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background: $content;

  &:focus {
    outline: none;
    border-color: $primary;
  }
  
  &:disabled {
    background: $input-disabled-bg;
  }
}

.Info {
  font-style: italic;
  margin: 0.25rem 0 0 0;
}

.InfoDanger {
  color: $danger;
}

.TopInfo {
  font-style: italic;
  margin: 0.25rem 0 0.25rem 0;
}
