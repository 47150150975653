@import '../../../assets/scss/common';

.Container {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  background: $danger;
  border-radius: 4px;
  border: 1px solid darken($color: $danger, $amount: 10%);
  color: #FFF;
}
