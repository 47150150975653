@import "../../assets/scss/custom";

.Container {
  background: $white;
  padding: 1rem;
  margin: 1rem;
  border-radius: 4px;
  width: calc(100% - 2rem);
  align-self: center;

  @media (min-width: 1200px) {
    width: 75%;
  }
}

.ReservationInfo p {
  margin: 0;
  line-height: 1.4;
}

.StationInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
}
