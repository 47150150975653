@import "../../assets/scss/custom";

.NavBar {
  display: flex;
  margin-bottom: 0;
  border-bottom: 1px solid #E5E5E5;
  z-index: 100;
  background: $primary;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  color: $white;
}

.Logo {
  height: 50px;
  max-width: 265px;
  margin-left: 0.5rem;
}

.Logout {
  margin-right: 0.5rem;
  color: $white;
  background: none;
  font-size: 1.25rem;
}

.Logout:hover {
  color: $white;
}

@media (min-width: $mobile-width) {
  .Logo {
    height: 60px;
    width: 265px;
    margin-left: 1rem;
  }

  .Logout {
    margin-right: 1rem;
  }
}
