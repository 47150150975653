@import '../../assets/scss/custom';

.ErrorMessage {
    margin-top: 1rem;
}

.Container {
    display: grid;
    align-content: center;
    justify-content: center;
    height: 100vh;
}

.LoginBox {
    max-width: 600px;
    background: $white;
    padding: 3rem;
    // text-align: center;
}

.Title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 2rem;
}

.Inputs {
    margin-bottom: 2rem;
}

.ButtonContainer {
    text-align: center;
}

.Button {
    width: 50%;
    color: $white;
    border-radius: 0;
    border: 0;
    border-bottom: 4px solid #f0ac2b;
    padding: 20px 0;
    background: $secondary;
}

.LinksContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;

    button {
        color: $white;
    }
}

.Border {
    border-right: 1px solid $gray-1;
}

.LogoContainer {
  margin-bottom: 1rem;
  text-align: center;
}

.Logo {
  height: 60px;
  background: $primary;
  max-width: 265px
}

@media (min-width: $mobile-width) {
  .LoginBox {
    width: 600px;
  }

  .Logo {
    width: 265px;
  }
}
