$header: #FFF;

$primary: #4398d8;
$success: #8fbb30;
$danger: #bb4c30;

$content: #FFF;

$background: #F3F3F3;

$dark-gray: #b7b7b7;
$light-gray: #DDD;

$table-hover: #f5f5f5;

$input-disabled-bg: #e9ecef;

@mixin remove-icon {
  position: absolute;
  top: -.25rem;
  right: -.5rem;
  color: #FFF;
  background: $primary;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
