$gray-1: #333333;
$gray-3: #828282;
$gray-4: #BDBDBD;
$gray-5: #E0E0E0;
$gray-6: #F2F2F2;

$primary: #041b26;
$secondary: #fbc427;

$white: #FFFFFF;
$white-2: #f8f9f8;

$text-color: #131313;
$border-color: #dfdfe1;
$border-radius: 3px;

$font-size-xs: 0.7rem;
$font-size-sm: 0.875rem;
$font-size-md: 0.925rem;
$font-size-label: 0.8125rem;

$mobile-width: 768px;

@mixin input-styles {
  color: $text-color;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  font-size: $font-size-sm;
  background: $white;

  &:focus {
    background: $white;
  }

  &::placeholder {
    color: $gray-3;
    padding: 0;
    margin: 0;
  }
}

@mixin form-content {
  background: $content-bg;
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;

  h4 {
    margin-bottom: 2rem;
  }
}

@mixin subtitle {
  color: $gray-3;
  font-weight: 500;
  font-size: $font-size-md;
}
